@import "mixes";

.static-page
{
    background-color: #fcfbf3!important;
}


.builder
{
    width: 100%;
    
    .center
    {
        margin: 0 auto;
        max-width: 1000px;
        text-align: left;
    }
    
    
    .title
    {
        font-size: 21px;
        font-family: 'Philosopher', sans-serif;
        margin-bottom: 20px;
        
    }
    
    .show-more
    {
        margin-bottom: 20px;
        
        .more
        {
            border: 1px solid #000;        
            padding: 5px 20px;
            display: table;
            font-size: 12px;
            letter-spacing: 1px;
            
            &:hover    
            {
                background-color: #000;
                color: #fff;
            }            
        }
        
    }
    
    .text
    {
        font-size: 14px;
        letter-spacing: 1px;
        font-family: 'proxima_nova_ltlight';
        margin-bottom: 40px;
        
        a:hover
        {
            color: #838282;
        }
        
    }
    
    .image-container
    {
        background-color: #f8f5e7;
        width: 74%;
        padding: 88px 0px;
        margin-bottom: 40px;
        
        .img
        {
            display: table;
            margin-left: 30%;
        }
            
    }
}




@media screen and (max-width: 1200px)
{
    .main-wrapper .photographies-wrapper .legend-container .legend
    {
        width: 90%;
    }
    
    .builder
    {
        margin: 0 auto;
        width: 90%;    
    }
        
}

@media screen and (max-width: 600px)
{
    .builder .image-container
    {
        padding: 40px 0px;    
    }
    
    .builder .image-container .img
    {
        margin-left: 60px;    
    }
    
    .builder .image-container .img img
    {
        height: auto!important;
        width: 240px;
    }
            
}

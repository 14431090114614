@import "mixes";
* {
	outline: none;
}

html {
	width: 100%;
	height: 100%;
}

body {
	font-family: 'proxima_nova_rgregular';
	width: 100%;
	height: 100%;
	min-width: 300px;
	//overflow-x: hidden;
}

.img-responsive {
	display: block;
	max-width: 100%;
	height: auto;
}


/*Typography*/

a {
	text-decoration: none;
	transition: all 0.1s ease-in-out;
	-moz-transition: all 0.1s ease-in-out;
	-webkit-transition: all 0.1s ease-in-out;
	-o-transition: all 0.1s ease-in-out;
	outline: none;
	color: #000;
}

a:hover {
	text-decoration: none;
	color: #cbcbcb;
}

a:focus {
	text-decoration: none;
	color: #000;
	outline: none;
}

.container {
	width: 100%;
	min-width: 300px;
	max-width: 1920px;
}

#temp-container {
	position: fixed;
	left: -999px;
	top: -999px;
	width: 1px;
	height: 1px;
	overflow: hidden;
}

#scroller {
	position: absolute;
	z-index: 1;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	width: 100%;
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
	-o-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-text-size-adjust: none;
	-moz-text-size-adjust: none;
	-ms-text-size-adjust: none;
	-o-text-size-adjust: none;
	text-size-adjust: none;
}

#scroller ul {
	list-style: none;
	padding: 0;
	margin: 0;
	width: 100%;
	text-align: left;
}

#scroller li {
	padding: 0 10px;
	height: 40px;
	line-height: 40px;
	border-bottom: 1px solid #ccc;
	border-top: 1px solid #fff;
	background-color: #fafafa;
	font-size: 14px;
}

#noscriptmsg {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 999;
	background-color: rgba(#000, 0.9);
	color: #fff;
	font-size: 20px;
	.no-js {
		@include centerDivXY();
		text-align: center;
		span {
			margin-top: 40px;
			display: block;
		}
	}
}

.cookie-container {
	position: fixed;
	width: 100%;
	height: 40px;
	line-height: 40px;
	bottom: 0;
	left: 0;
	z-index: 990;
	background-color: #fff;
	color: #000;
	border-top: 1px solid #cbcbcb;
	.cookie-data {
		line-height: 40px;
		.accept {
			position: absolute;
			right: 10px;
			top: 13px;
			font-size: 11px;
			color: #000;
		}
		.text {
			width: 100%;
			text-align: center;
			a {
				color: #cbcbcb;
				margin-left: 15px;
				text-decoration: underline;
			}
		}
	}
}

.sbut {
	position: fixed;
	bottom: 10px;
	right: 10px;
	font-family: 'proxima_nova_ltlight';
	text-transform: uppercase;
	font-size: 12px;
	padding: 5px;
	border: 1px solid transparent;
	color: rgba(#fff, 0.5);
	z-index: 800;
	@include transition(all 0.3s ease 0s);
	cursor: pointer;
	&:hover {
		border: 1px solid rgba(#fff, 0.5);
	}
	&.photographies-view {
		position: absolute;
		bottom: auto;
		top: 62px;
		color: rgba(#000, 1);
		&:hover {
			border: 1px solid rgba(#000, 1);
		}
	}
}

.unmute {
	display: none;
}

#prevent-landscape {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 900;
	background-color: #fff;
	display: none;
	.text {
		@include centerDivXY();
		text-align: center;
		font-size: 21px;
	}
}

#preloader {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 999;
	//background-color: #e8e6e6;
	background-color: #ebe9e1;
	.logo {
		@include centerDivXY();
		width: 100%;
		color: #000;
		font-family: 'Philosopher', sans-serif;
		font-size: 32px;
		text-align: center;
		letter-spacing: 2px;
		margin-top: -3px;
		span {
			font-size: 13px;
			display: block;
			letter-spacing: 1px;
			margin-bottom: 20px;
			text-transform: uppercase;
		}
		.animation {
			img {
				animation: spin 2s infinite linear;
				display: block;
				margin: 0 auto;
				width: 70px;
			}
		}
	}
}

@-moz-keyframes spin {
	from {
		-moz-transform: rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg);
	}
}

@-webkit-keyframes spin {
	from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}


/**

Menu

*/

.button-menu {
	background-color: #ffffff;
	height: 70px;
	width: 100px;
	top: 50%;
	left: 0;
	position: fixed;
	cursor: pointer;
	z-index: 800;
	@include centerDivY();
	.text {
		position: absolute;
		left: 50%;
		top: 14px;
		width: 49px;
		;
		font-family: 'Philosopher', sans-serif;
		font-size: 12px;
		letter-spacing: 1px;
		text-align: center;
		text-transform: lowercase;
		color: #000;
		@include transform(translate( -50%, 0%));
	}
	.lines {
		width: 49px;
		height: 2px;
		position: relative;
		background-color: #000;
		position: absolute;
		@include transform(translate( -50%, -50%));
		left: 50%;
		top: 55%;
		@include transition(all 0.3s ease);
	}
	.lines:before,
	.lines:after {
		content: "";
		background-color: #000;
		position: absolute;
		display: inline-block;
		left: 0px;
		width: 49px;
		height: 2px;
		@include transition(all 0.5s ease);
	}
	.lines:after {
		top: -6px;
		transition-delay: 100ms;
	}
	.lines:before {
		top: 6px;
		transition-delay: 120ms;
	}
	&:hover .lines:before,
	&:hover .lines:after {
		left: 10px;
	}
}

nav {
	position: fixed;
	top: 0;
	left: -100%;
	height: 100%;
	width: 100%;
	z-index: 900;
	background-color: #000;
	@include transition(all 0.3s ease);
	.btn-close {
		position: absolute;
		top: 40px;
		left: 87px;
		font-family: 'proxima_nova_ltlight';
		text-transform: uppercase;
		font-size: 11px;
		letter-spacing: 1px;
		cursor: pointer;
		z-index: 11;
		@include transition(all 0.3s ease);
		&:hover {
			color: #aab2b4;
		}
	}
	.menu {
		width: 333px;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
		background-color: #fff;
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			@include centerDivXY();
			li {
				list-style: none;
				margin: 0;
				padding: 0;
				margin-bottom: 20px;
				a {
					font-family: 'proxima_nova_ltlight';
					color: #010103;
					font-size: 19px;
					@include transition(all 0.3s ease);
					&:hover {
						color: #aab2b4;
					}
				}				
			}
		}
		
		.credits
		{
			position: absolute;
			bottom: 10px;
			width: 90%;
			text-align: center;
			font-family: 'proxima_nova_ltlight';
			color: #010103;
			font-size: 12px;
			
			.mobile
			{
				display: none;
			}
			
		}

	}
	.right-side {
		width: calc(100% - 333px);
		height: 100%;
		position: absolute;
		left: -100%;
		top: 0;
		z-index: 1;
		@include transition(all 0.3s ease 0.2s);
		.bg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			._bg {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: #000;
				background-size: cover;
				background-position: 50% 50%;
				@include transition(all 0.3s ease 0s);
				&.h {
					opacity: 0;
				}
				&.bg0 {
					background-image: url('/res/BG_menu_pormes2.jpg');
				}
				&.bg1 {
					background-image: url('/res/BG_menu_photos.jpg');
				}
				&.bg2 {
					background-image: url('/res/BG_menu_pormes.jpg');
				}
				&.bg3 {
					background-image: url('/res/BG_menu_reves.jpg');
				}
			}
			.bg-overlay {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(#000, 0.3);
			}
		}
		.col {
			background-color: rgba(#fff, 0.1);
			height: 100%;
			.button {
				width: 100%;
				height: 100%;
				border-right: 1px dotted #fff;
				a {
					color: #fcfbff;
					font-size: 36px;
					letter-spacing: 1px;
					font-family: 'Philosopher', sans-serif;
					margin-top: 60px;
					@include centerDivXY();
					@include transition(all 0.3s ease);
					.more {
						font-family: 'proxima_nova_ltlight';
						text-transform: uppercase;
						font-size: 12px;
						letter-spacing: 1px;
						@include transition(all 0.3s ease);
					}
					&:hover {
						color: rgba(#fcfbff, 0.5);
						.more {
							opacity: 0;
						}
					}
				}
			}
			&:last-child {
				.button {
					border-right: none;
				}
			}
		}
	}
	&.open {
		left: 0;
		.right-side {
			left: 348px;
		}
	}
}

.cursor-move {
	cursor: url("../images/move-32.png") 32 32, move;
}

.main-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	//background-color: #dbdbdb;
	background-color: #ebe9e1;
	.intro {
		position: absolute;
		top: 50%;
		left: 160px;
		color: #fff;
		font-family: 'Philosopher', sans-serif;
		z-index: 201;
		@include centerDivY();
		.vincent {
			float: left;
			.t1 {
				font-size: 31px;
				letter-spacing: 1px;
				text-transform: uppercase;
				@include transition(all 1s ease);
				opacity: 0;
			}
			.t2 {
				font-size: 21px;
				letter-spacing: 1px;
				opacity: 0;
				filter: blur(5px);
				@include transition(opacity 0.3s ease 0.5s);
				@include transition(filter 0.3s ease 0.2s);
			}
		}
		.line {
			float: left;
			height: 2px;
			width: 180px;
			background-color: #fff;
			margin-left: 10px;
			margin-top: 10px;
			opacity: 0;
			@include transition(all 0.3s ease 1s);
		}
		.text {
			float: left;
			margin-left: 10px;
			.t2 {
				font-size: 31px;
				letter-spacing: 1px;
				opacity: 0;
				@include transition(opacity 0.3s ease 1.5s);
			}
			.t1 {
				font-size: 21px;
				letter-spacing: 1px;
				opacity: 0;
				@include transition(all 0.3s ease 2s);
			}
		}
		&.show {
			.vincent {}
			.line {
				opacity: 1;
			}
			.vincent {
				.t1,
				.t2 {
					opacity: 1;
					filter: blur(0px);
				}
			}
			.text {
				.t1,
				.t2 {
					opacity: 1;
				}
			}
		}
	}
	.background-rotate {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		pointer-events: none;
		background-color: #000;
		.bg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-size: cover!important;
		}
		.bg-overlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(#000, 0.3);
			z-index: 2;
		}
		&.bg-home {
			.layer-1 {
				background: transparent url('/res/home/bg1.jpg') center right no-repeat;
			}
			.layer-2 {
				background: transparent url('/res/home/bg2.jpg') center right no-repeat;
			}
			.layer-3 {
				background: transparent url('/res/home/bg3.jpg') center right no-repeat;
			}
			.layer-4 {
				background: transparent url('/res/home/bg4.jpg') center right no-repeat;
			}
		}
		&.bg-poemes {
			.layer-1 {
				background: transparent url('/res/poemes/013.jpg') 50% 0 no-repeat;
			}
			.layer-2 {
				background: transparent url('/res/poemes/013b.jpg') 50% 0 no-repeat;
			}
		}
		&.bg-projects {
			.layer-1 {
				background: transparent url('/res/projects/Fond-marmite-a-projetA.jpg') 50% 50% no-repeat;
			}
			.layer-2 {
				background: transparent url('/res/projects/Fond-marmite-a-projetB.jpg') 50% 50% no-repeat;
			}
			.layer-3 {
				background: transparent url('/res/projects/Fond-marmite-a-projetC.jpg') 50% 50% no-repeat;
			}
		}
		&.bg-dreams {
			.layer-1 {
				background: transparent url('/res/dreams/Reves-bg2.jpg') 50% 0 no-repeat;
			}
			.layer-2 {
				background: transparent url('/res/dreams/Bg-revesD.jpg') 50% 0 no-repeat;
			}
			.layer-3 {
				background: transparent url('/res/dreams/Bg-reves-D2.jpg') 50% 0 no-repeat;
			}
		}
	}
	.poemes-bg {
		background: transparent url('/res/poemes/bg.jpg') 50% 50% no-repeat;
		background-size: cover;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
	.animation-dots {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
		.dots {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			@include transition(opacity 2s ease);
			&.fade {
				opacity: 0.2;
			}
			&.rotate {
				@include transform( rotate(30deg));
			}
		}
	}
	.photographies-dots {
		//z-index: 101;
		pointer-events: none;
		.layer-1 {
			background: transparent url('/res/photographies/dot-1.png') center right no-repeat;
			background-size: cover;
			z-index: 1;
			width: 1885px;
			height: 1212px;
			@include centerDivXY();
		}
		.layer-2 {
			background: transparent url('/res/photographies/dot-2.png') center right no-repeat;
			background-size: cover;
			z-index: 2;
		}
		.dot {
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			@include transition(all 2s ease,
			opacity 0.3s ease);
			&.fade {
				opacity: 1;
			}
		}
	}
	.poemes-dots {
		z-index: 101;
		pointer-events: none;
		.layer-1 {
			background: transparent url('/res/poemes/blue_dots.png') center right no-repeat;
			background-size: cover;
			z-index: 1;
			width: 1885px;
			height: 1212px;
			@include centerDivXY();
		}
		.layer-2 {
			background: transparent url('/res/poemes/shadow.png') center right no-repeat;
			background-size: cover;
			z-index: 2;
		}
	}
	.poemes-inner-dots {
		//z-index: 100;
		//pointer-events:none;
		z-index: 0;
		.layer-1 {
			background: transparent url('/res/poemes/inner_blue.png') center right no-repeat;
			background-size: contain;
			z-index: 1;
			opacity: 0.6;
			@include centerDivXY();
		}
		.layer-2 {
			background: transparent url('/res/poemes/inner_dark.png') center right no-repeat;
			background-size: contain;
			z-index: 2;
			opacity: 0.8;
		}
		.gradient-bg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			-webkit-animation: AnimationBG 5s ease infinite;
			-moz-animation: AnimationBG 5s ease infinite;
			animation: AnimationBG 5s ease infinite;
			&.bg-1 {
				background: linear-gradient(270deg, #e9e9e1, #a3a7b6);
				background-size: 200% 200%;
			}
			&.bg-2 {
				background: linear-gradient(270deg, #fffffe, #f5f0c0);
				background-size: 200% 200%;
			}
			&.bg-3 {
				background: linear-gradient(270deg, #f6f1ca, #b4aea8);
				background-size: 200% 200%;
			}
			&.bg-4 {
				background: linear-gradient(270deg, #c4ccd2, #f4ebe5);
				background-size: 200% 200%;
			}
			&.bg-5 {
				background: linear-gradient(270deg, #e5f0f4, #f6e0b9);
				background-size: 200% 200%;
			}
			&.bg-6 {
				background: linear-gradient(270deg, #e5ccae, #f8f2ec);
				background-size: 200% 200%;
			}
			&.bg-7 {
				background: linear-gradient(270deg, #eae1df, #bbbcc8);
				background-size: 200% 200%;
			}
			&.bg-8 {
				background: linear-gradient(270deg, #e5f0f4, #f6e1b9);
				background-size: 200% 200%;
			}
			&.bg-9 {
				background: linear-gradient(270deg, #bdaaaf, #fff9e9);
				background-size: 200% 200%;
			}
		}
	}
	.dreams-inner-dots {
		z-index: 0;
		pointer-events: none;
		.dot {
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			@include transition(all 10s ease,
			opacity 2s ease);
			&.fade {
				opacity: 0.8;
			}
		}
	}
	.retour {
		position: absolute;
		left: 0;
		top: 62px;
		z-index: 100;
		a {
			text-transform: uppercase;
			font-size: 12px;
			letter-spacing: 1px;
			display: block;
			.line {
				width: 34px;
				height: 1px;
				float: left;
				margin-top: 9px;
				background-color: #000;
				@include transition(all 0.3s ease 0s);
			}
			.text {
				position: absolute;
				left: 40px;
				top: 0;
				@include transition(all 0.3s ease 0s);
			}
			&:hover {
				color: #000!important;
				.line {
					width: 45px;
					@include transition(all 0.3s ease 0.1s);
				}
				.text {
					left: 50px;
					@include transition(all 0.3s ease 0s);
				}
			}
		}
	}
	.go-back {
		position: fixed;
		top: 0;
		left: calc(100% - 42px);
		height: 100%;
		width: 50%;
		background-color: #fff;
		z-index: 200;
		@include transition(all 0.4s ease 0s);
		.overlay {
			width: 72px;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
			cursor: pointer;
		}
		.button {
			position: absolute;
			top: 50%;
			height: 42px;
			left: -129px;
			width: 300px;
			letter-spacing: 1px;
			text-align: center;
			@include transform(rotate( 90deg)) text-transform: uppercase;
			font-family: 'proxima_nova_rgregular';
			z-index: 1;
			&.button_dreams {
				top: 20%;
				width: 100px;
				left: -29px;
				z-index: 10;
				a:hover {
					color: rgba(#000, 0.5);
				}
			}
			.text {
				position: absolute;
				@include centerDivX();
				top: 11px;
				left: 50%;
				width: 100%;
				font-size: 12px;
			}
			.line {
				position: absolute;
				@include centerDivX();
				bottom: 0;
				left: 50%;
				height: 4px;
				width: 93px;
				background-color: #040001;
			}
		}
		
		.title-mobile
		{
			font-size: 36px;
			line-height: 36px;
			font-family: 'Philosopher', sans-serif;
			letter-spacing: 1px;
			margin-bottom: 30px;
			padding-left: 20px;
			margin-top: 60px;
			margin-left: 20px;
			padding-top: 10px;
			position: relative;				
			display: none;
			
			.line
			{
				position: absolute;
				top: 0;
				left: 0px;
				height: 4px;
				width: 93px;
				background-color: #000;
			}
			
			.more
			{
				font-size:14px;
				font-family: 'proxima_nova_ltlight';
			}
			
		}
		
				
				
		.list-all {
			position: absolute;
			top: 0;
			left: 140px;
			width: calc(100% - 140px);
			height: 100%;
			opacity: 0;
			filter: blur(8px);
			@include transition(opacity 0.5s ease 0.2s,
			filter 0.5s ease 0.4s);
			.list-wrapper {
				position: absolute;
				top: 55%;
				left: 0;
				width: 100%;
				max-width: 400px;
				height: 70%;
				overflow: hidden;
				@include centerDivY();
				
				.list {
					margin-bottom: 40px;
					padding-right: 15px;
					a {
						font-family: 'Philosopher', sans-serif;
						font-size: 20px;
						margin-bottom: 20px;
						display: block;
						.image {
							display: none;
							margin-bottom: 10px;
							img {
								width: 100%;
							}
						}
						/*
                br
                {
                    display: inline-table;
                    content: ' ';
                    margin-left: 5px;
                }
                */
					}
					.line-bottom {
						margin-right: 60px;
						float: right;
						height: 1px;
						width: 70%;
						background-color: rgba(#000, 0.3);
					}
				}
			}
		}
		&.show {
			left: 50%;
			.list-all {
				opacity: 1;
				filter: blur(0px);
			}
		}
	}
	.sbut {
		&.photographies {
			right: 70px;
			color: #000;
			&:hover {
				border: 1px solid rgba(#000, 0.5);
			}
		}
	}
	.sbut.poemes {
		right: 70px;
	}
	section.mosaique-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		z-index: 100;
		div.mosaique {
			position: absolute;
			.image-grid {
				position: absolute;
				.image {
					position: absolute;
					z-index: 1;
					.image-blur {
						position: absolute;
						z-index: 2;
						width: 100%;
						height: 100%;
						@include transition(all 0.3s ease);
					}
					.legend-container {
						position: absolute;
						top: 50%;
						right: 0;
						//width: 300px;
						opacity: 0;
						@include transition(all 0.3s ease);
						@include centerDivY();
						.legend {
							.line {
								width: 95px;
								height: 4px;
								background-color: #040001;
								margin-bottom: 2px;
							}
							.title {
								font-family: 'Philosopher', sans-serif;
								color: #010103;
								font-size: 21px;
								margin-bottom: 5px;
								white-space: nowrap;
							}
							.more {
								font-family: 'proxima_nova_ltlight';
								color: #010103;
								font-size: 11px;
								letter-spacing: 1px;
								text-transform: uppercase;
							}
						}
						&.sright {
							//right: -350px;
							opacity: 1;
						}
						&.sleft {
							right: auto;
							//left: -350px;
							opacity: 1;
						}
					}
					&:hover {
						/*
                    .legend-container
                    {
                        right: -350px;
                        opacity: 1;
                    }
                    */
						.image-blur {
							opacity: 0;
						}
					}
				}
			}
		}
	}
	.photographies-wrapper {
		position: relative;
		display: table;
		width: 100%;
		padding-bottom: 100px;
		//background-color: #dbdbdb;
		background-color: #f8f5e7;
		.legend-container {
			margin-bottom: 72px;
			padding-bottom: 100px;
			padding-top: 260px;
			background-color: #fff;
			width: 100%;
			@include transition(all 0.3s ease);
			.legend {
				position: absolute;
				left: 50%;
				top: 120px;
				@include centerDivX();
				.line {
					width: 95px;
					height: 4px;
					background-color: #040001;
					margin-bottom: 10px;
				}
				.bottom-dotted-line {
					border-bottom: 1px dotted #878687;
					width: 115px;
				}
				.title {
					font-family: 'Philosopher', sans-serif;
					color: #010103;
					font-size: 36px;
					line-height: 43px;
					margin-bottom: 5px;
				}
				.category {
					font-family: 'proxima_nova_ltlight';
					color: #010103;
					font-size: 13px;
					letter-spacing: 1px;
					text-transform: uppercase;
					border-bottom: 1px dotted #878687;
					padding-bottom: 10px;
					padding-right: 20px;
					display: table;
				}
			}
			.text,
			.resume {
				margin: 0 auto;
				font-family: 'proxima_nova_ltlight';
				color: #010103;
				font-size: 14px;
				letter-spacing: 1px;
				max-width: 1000px;
				p {
					padding: 0;
					margin: 0;
					strong,
					b {
						font-family: 'Philosopher', sans-serif;
						font-size: 21px;
						font-weight: normal;
					}
					em {
						font-style: initial;
					}
				}
			}
			.text {
				display: none;
			}
			.show-more {
				margin: 0 auto;
				margin-top: 70px;
				.more {
					text-transform: uppercase;
					font-family: 'proxima_nova_ltlight';
					color: #010103;
					font-size: 12px;
					letter-spacing: 1px;
					padding: 5px 10px;
					border: 1px solid #000000;
					cursor: pointer;
					display: table;
					@include transition(all 0.3s ease);
					&:hover {
						background: #000;
						color: #fff;
					}
				}
			}
			.hide-more {
				display: none;
			}
		}
		.mosaique {
			margin: 0 auto;
			float: none;
			overflow: hidden;
			padding: 0;
			width: 100%;
			.image-grid {
				padding: 0;
				position: relative;
				width: 33.333%;
                padding: 70px;
				@include transition(all 0.3s ease 0s);
				.image {
					width: 100%;
					img {
						width: 100%;
						display: block;
					}
				}
				&.move-top-50 {
					margin-top: 50px;
				}
				&.move-top-70 {
					margin-top: 70px;
				}
				&.move-top-140 {
					margin-top: 140px;
				}
				&.move-left-20 {
					margin-left: 20px;
				}
				&.move-left-30 {
					margin-left: 30px;
				}
				&.move-left-40 {
					margin-left: 40px;
				}
				&:hover {
					@include transform(scale(1.2));
				}
			}
		}
	}
}

.zoom-header {
	width: 100%;
	height: 70px;
	line-height: 70px;
	background-color: #fff;
	display: none;
	z-index: 1100;
	position: fixed;
	top: 0;
	left: 0;
	.title {
		font-family: 'Philosopher', sans-serif;
		font-size: 24px;
		float: left;
		margin-left: 51px;
		.line {
			display: inline-block;
			margin-left: 20px;
			margin-top: 34px;
			width: 100px;
			height: 3px;
			background-color: #000;
		}
	}
	.actions {
		float: right;
		margin-right: 51px;
		font-family: 'proxima_nova_ltlight';
		font-size: 12px;
		text-transform: uppercase;
	}
	div {
		float: right;
		margin-left: 31px;
	}
	.cl {
		cursor: pointer;
		@include transition(all 0.3s ease 0s);
		&:hover {
			opacity: 0.5;
		}
	}
}

.zoom-footer {
	width: 100%;
	height: 60px;
	background-color: #fff;
	display: none;
	z-index: 1100;
	position: fixed;
	bottom: 0;
	left: 0;
	.buy-button {
		font-family: 'proxima_nova_ltlight';
		font-size: 11px;
		line-height: 11px;
		text-transform: uppercase;
		float: right;
		margin-right: 51px;
		margin-top: 16px;
		border: 1px solid #000;
		padding: 5px;
		cursor: pointer;
		@include transition(all 0.3s ease 0s);
		span {
			display: block;
			padding-top: 1px;
		}
		&:hover {
			background-color: #000;
			color: #fff;
		}
	}
	.text {
		font-family: 'proxima_nova_ltlight';
		font-size: 16px;
		float: left;
		margin-left: 31px;
		margin-top: 18px;
	}
}

.prices-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #fff;
	text-align: center;
	z-index: 1300;
	display: none;
	.close-prices {
		position: absolute;
		top: 36px;
		right: 51px;
		font-family: 'proxima_nova_ltlight';
		font-size: 12px;
		text-transform: uppercase;
		cursor: pointer;
		@include transition(all 0.3s ease 0s);
		&:hover {
			opacity: 0.5;
		}
	}
	.list-wrapper-price {
		overflow: hidden;
		width: 100%;
		height: calc(100% - 260px);
		max-width: 970px;
		position: absolute;
		top: 130px;
		left: 50%;
		@include centerDivX();
		.title {
			font-size: 30px;
			font-family: 'Philosopher', sans-serif;
			margin-bottom: 10px;
		}
		.text {
			margin-bottom: 30px;
			font-family: 'proxima_nova_ltlight';
			font-size: 16px;
		}
		.text2 {
			font-family: 'Philosopher', sans-serif;
			font-size: 18px;
			line-height: 25px;
		}
		.line {
			margin: 0 auto;
			width: 95%;
			height: 1px;
			background-color: #000;
		}
		.table {
			margin: 0 auto;
			width: 200px;
			margin-top: 20px;
			.col {
				width: 200px;
				margin-bottom: 10px;
				display: table;
				.r {
					float: left;
					width: 33%;
					span {
						margin: 0 auto;
						display: block;
						width: 90%;
						height: 1px;
						border-bottom: 1px dotted #000;
						margin-top: 13px;
					}
				}
			}
			&.table-m {
				.col {
					margin-bottom: 40px;
				}
			}
		}
	}
}

@-webkit-keyframes AnimationBG {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@-moz-keyframes AnimationBG {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@keyframes AnimationBG {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@media screen and (max-width: 1500px) {
	nav .right-side .col .button a {
		font-size: 28px;
	}
	.main-wrapper .intro .vincent .t1,
	.main-wrapper .intro .text .t2 {
		font-size: 26px;
	}
	.main-wrapper .intro .vincent .t2,
	.main-wrapper .intro .text .t1 {
		font-size: 18px;
	}
	.main-wrapper .intro .line {
		width: 100px;
	}
}

@media screen and (max-width: 1300px) {
	nav .menu {
		width: 250px;
	}
	nav .right-side {
		left: 265px!important;
		width: calc(100% - 250px);
	}
	nav .right-side .col .button a {
		font-size: 24px;
	}
	nav .menu ul li a {
		font-size: 16px;
	}
    
    .main-wrapper .photographies-wrapper .legend-container .legend,
    .main-wrapper .photographies-wrapper .legend-container .text,
    .main-wrapper .photographies-wrapper .legend-container .resume
    {
        width: 90%;
    }
    
    
}

@media screen and (max-width: 1000px) {
	.button-menu {
		height: 50px;
		width: 70px;
	}
	.button-menu .text {
		display: none;
	}
	.button-menu .lines {
		top: 50%;
		width: 35px;
	}
	.button-menu .lines:before,
	.button-menu .lines:after {
		width: 35px;
	}
	nav .right-side .col {
		height: 33%;
		width: 100%;
	}
	nav .right-side .col .button {
		border: none
	}
	nav .right-side .col .button a {
		margin-top: 0;
		text-align: center;
	}
	.main-wrapper .intro {
		width: 70%;
		left: 50%;
		@include centerDivXY();
		text-align: center;
	}
	.main-wrapper .intro .vincent,
	.main-wrapper .intro .line,
	.main-wrapper .intro .text {
		float: none;
		margin: 0 auto;
		margin-bottom: 30px;
	}
	.main-wrapper .go-back {
		width: 100%;
		left: 0;
	}
	.main-wrapper .go-back .list-all {
		opacity: 1;
		filter: blur(0px);
		top:140px;
		height: calc(100% - 140px);
	}
	
	.main-wrapper .go-back .overlay,
	.main-wrapper .go-back .button {
		display: none;
	}
	
	.main-wrapper .go-back .list-all .list-wrapper .list a .image {
		display: block;
	}
	
	.main-wrapper .go-back .list-all .list-wrapper
	{
		top:145px;	
	}
	
	.main-wrapper .go-back .title-mobile
	{
		display: block;
	}
	
	.main-wrapper .photographies-wrapper .mosaique .image-grid {
		padding: 30px;
	}
	
	
	nav .menu ul
	{
		position: relative;
		@include translate(0%,0%);
		top:auto;
		left:auto;
		width: 80%;
		margin: 0 auto;
		margin-top: 100px;
	}
	
	nav .menu .credits
	{
		position: relative;
		bottom: auto;
		left: auto;
		width: inherit;
		font-size: inherit;
		width: 80%;
		margin: 0 auto;
		text-align: left;
		
		.desktop
		{
			display: none;
		}
		
		.mobile
		{
			display: block;
		}
			
	}
	
	
}

@media screen and (max-width: 768px) {
	.button-menu {
		top: 0;
		transform: translate(0%, 0%);
	}
	.main-wrapper .go-back .list-all {
		left: 50%;
		width: 90%;
		@include centerDivX();
	}

    .main-wrapper .photographies-wrapper .mosaique .image-grid {
		width: 50%;
	}

}

@media screen and (max-width: 600px) {
	nav .menu {
		width: 180px;
	}
	nav .menu ul {
		width: 80%;
	}
	nav .menu .credits,
	nav .menu ul li a {
		font-size: 14px;
	}
	
	
	nav .btn-close {
		left: 20px;
	}
	nav .right-side {
		left: 195px!important;
		width: calc(100% - 180px);
	}
	nav .right-side .col .button a {
		font-size: 18px;
	}
	nav .right-side .col .button a .more {
		font-size: 12px;
	}
}


@media screen and (max-width: 500px) {
	
    
    .main-wrapper .photographies-wrapper .mosaique .image-grid {
		width: 100%;
	}

}
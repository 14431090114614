$DEBUG:true;

// generic transform
@mixin transform($transforms...)
{
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

@mixin transitionEase($propertie, $time)
{
    -webkit-transition: $propertie $time ease;
    -moz-transition: $propertie $time ease;
    -ms-transition: $propertie $time ease;
    -o-transition: $propertie $time ease;
    transition: $propertie $time ease;
}

@mixin transition($properties...)
{
    transition: $properties;
    -webkit-transition: $properties;
    -moz-transition: $properties;
    -ms-transition: $properties;
    -o-transition: $properties;
    
}

@mixin transformOrigin($properties...)
{
    -webkit-transform-origin: $properties;
    -moz-transform-origin: $properties;
    -ms-transform-origin: $properties;
    -o-transform-origin: $properties;
    transform-origin: $properties;
}

@mixin borderRadius($radius)
{
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}

// translate
@mixin translate ($x, $y)
{
    @include transform(translate($x, $y));
}

@mixin centerDivX()
{
    @include transform(translate( -50%, 0%));
}

@mixin centerDivY()
{
    @include transform(translate( 0%, -50% ));
}

@mixin centerDivXY()
{
    position: absolute;
    @include transform(translate( -50%, -50%));
    left: 50%;
    top:50%;
}

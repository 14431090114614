
.no-padding
{
    padding: 0!important;
}

.col-centered
{
    float: none;
    margin: 0 auto;
}



/* =============
   Helper clasess
============= */
.p-0 {
  padding: 0px !important;
}
.p-20 {
  padding: 20px !important;
}
.p-30 {
  padding: 30px !important;
}
.p-l-0 {
  padding-left: 0px !important;
}

.p-l-5 {
  padding-left: 5px !important;
}

.p-l-10 {
  padding-left: 10px !important;
}

.p-r-0 {
  padding-right: 0px !important;
}

.p-r-5 {
  padding-right: 5px !important;
}

.p-r-10 {
  padding-right: 10px !important;
}


.p-t-0 {
  padding-top: 0px !important;
}
.p-t-10 {
  padding-top: 10px !important;
}
.p-b-0 {
  padding-bottom: 0px !important;
}
.p-b-10 {
  padding-bottom: 10px !important;
}
.p-l-r-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.m-0 {
  margin: 0px !important;
}
.m-r-5 {
  margin-right: 5px !important;
}
.m-r-10 {
  margin-right: 10px !important;
}
.m-r-15 {
  margin-right: 15px !important;
}
.m-l-5 {
  margin-left: 5px !important;
}
.m-l-10 {
  margin-left: 10px !important;
}
.m-l-15 {
  margin-left: 15px !important;
}
.m-t-5 {
  margin-top: 5px !important;
}
.m-t-0 {
  margin-top: 0px !important;
}
.m-t-10 {
  margin-top: 10px !important;
}
.m-t-15 {
  margin-top: 15px !important;
}
.m-t-20 {
  margin-top: 20px !important;
}
.m-t-30 {
  margin-top: 30px !important;
}
.m-t-40 {
  margin-top: 40px !important;
}
.m-b-0 {
  margin-bottom: 0px !important;
}
.m-b-5 {
  margin-bottom: 5px !important;
}
.m-b-10 {
  margin-bottom: 10px !important;
}
.m-b-15 {
  margin-bottom: 15px !important;
}
.m-b-20 {
  margin-bottom: 20px !important;
}
.m-b-30 {
  margin-bottom: 30px !important;
}
.w-xs {
  min-width: 80px;
}
.w-sm {
  min-width: 95px;
}
.w-md {
  min-width: 110px;
}
.w-lg {
  min-width: 140px;
}
.m-h-40 {
  min-height: 40px;
}
.m-h-50 {
  min-height: 50px;
}
.l-h-34 {
  line-height: 34px;
}
.font-600 {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}
.font-normal {
  font-weight: normal;
}
.font-light {
  font-weight: 300;
}
.font-13 {
  font-size: 13px !important;
}
.wrapper-md {
  padding: 20px;
}
.pull-in {
  margin-left: -15px;
  margin-right: -15px;
}
.pull-in-card {
  margin-left: -20px !important;
  margin-right: -20px !important;
}
.b-0 {
  border: none !important;
}
.vertical-middle {
  vertical-align: middle;
}
.bx-shadow {
  -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
}
.mx-box {
  max-height: 380px;
  min-height: 380px;
}
.thumb-sm {
  height: 32px;
  width: 32px;
}
.thumb-md {
  height: 48px;
  width: 48px;
}
.thumb-lg {
  height: 88px;
  width: 88px;
}
